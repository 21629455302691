import {
  DataSourceSystem,
  DataType,
  JobStatus,
  IndustryDivision,
  TenantRole,
  WorkspaceRole,
  JobType,
  CompanyType,
  ApiIntegrationType,
  DataIntegrationType,
  IndustryMajorGroup,
  IndustryGroup,
  IndustryDetail,
  Chart,
  ChartGroup,
} from "../types";

// テナント

export const TenantRoleTextDef = new Map<TenantRole, string>([
  ["owner", "member.role.owner"],
  ["reader", "member.role.reader"],
]);

export const FormulaTextDef = new Map<Chart, string>([
  ["gender_pay_gap", "formula.gender-pay-gap"],
  ["female_manager_ratio", "formula.female-manager-ratio"],
  ["male_childcare_leave_rate", "formula.male-childcare-leave-rate"],
  ["male_childcare_leave_rate_by_employee_type", "formula.male-childcare-leave-rate-by-employee-type"],
  [
    "male_childcare_leave_or_childcare_purpose_leave_rate",
    "formula.male-childcare-leave-or-childcare-purpose-leave-rate",
  ],
  ["gender_composition_of_all_employee", "formula.gender-composition-of-all-employee"],
  ["gender_composition_of_regular_employee", "formula.gender-composition-of-regular-employee"],
  ["gender_composition_of_non_regular_employee", "formula.gender-composition-of-non-regular-employee"],
  ["manager_rate", "formula.manager-rate"],
  ["span_of_control", "formula.span-of-control"],
  ["province_span_of_control_of_executive", "formula.province-span-of-control-of-executive"],
  ["province_span_of_control_of_executive_officer", "formula.province-span-of-control-of-executive-officer"],
  ["province_span_of_control_of_general_manager", "formula.province-span-of-control-of-general-manager"],
  ["province_span_of_control_of_division_manager", "formula.province-span-of-control-of-division-manager"],
  ["province_span_of_control_of_section_manager", "formula.province-span-of-control-of-section-manager"],
  ["direct_span_of_control_of_executive", "formula.direct-span-of-control-of-executive"],
  ["direct_span_of_control_of_executive_officer", "formula.direct-span-of-control-of-executive-officer"],
  ["direct_span_of_control_of_general_manager", "formula.direct-span-of-control-of-general-manager"],
  ["direct_span_of_control_of_division_manager", "formula.direct-span-of-control-of-division-manager"],
  ["direct_span_of_control_of_section_manager", "formula.direct-span-of-control-of-section-manager"],
  ["average_age_of_all_employee_by_gender", "formula.average-age-of-all-employee-by-gender"],
  ["average_age_of_regular_employee_by_gender", "formula.average-age-of-regular-employee-by-gender"],
  ["average_age_of_executive_officer_by_gender", "formula.average-age-of-executive-officer-by-gender"],
  ["average_age_of_general_manager_by_gender", "formula.average-age-of-general-manager-by-gender"],
  ["average_age_of_division_manager_by_gender", "formula.average-age-of-division-manager-by-gender"],
  ["average_age_of_section_manager_by_gender", "formula.average-age-of-section-manager-by-gender"],
  ["average_age_of_team_leader_by_gender", "formula.average-age-of-team-leader-by-gender"],
  ["average_age_of_staff_by_gender", "formula.average-age-of-staff-by-gender"],
  ["average_age_of_manager_by_gender", "formula.average-age-of-manager-by-gender"],
  [
    "ratio_age_distribution_of_regular_employee_by_gender",
    "formula.ratio-age-distribution-of-regular-employee-by-gender",
  ],
  ["ratio_age_distribution_of_manager_by_gender", "formula.ratio-age-distribution-of-manager-by-gender"],
  ["average_service_year_of_all_employee_by_gender", "formula.average-service-year-of-all-employee-by-gender"],
  ["average_service_year_of_regular_employee_by_gender", "formula.average-service-year-of-regular-employee-by-gender"],
  [
    "average_service_year_of_non_regular_employee_by_gender",
    "formula.average-service-year-of-non-regular-employee-by-gender",
  ],
  ["full_time_employee_average_service_year_by_gender", "formula.full-time-employee-average-service-year-by-gender"],
  ["gender_average_service_year_gap_of_all_employee", "formula.gender-average-service-year-gap-of-all-employee"],
  [
    "gender_average_service_year_gap_of_regular_employee",
    "formula.gender-average-service-year-gap-of-regular-employee",
  ],
  [
    "gender_average_service_year_gap_of_non_regular_employee",
    "formula.gender-average-service-year-gap-of-non-regular-employee",
  ],
  ["full_time_employee_gender_average_service_year_gap", "formula.full-time-employee-gender-average-service-year-gap"],
  [
    "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender",
    "formula.annual-new-graduate-employed-three-year-retention-rate-of-college-graduate-by-gender",
  ],
  [
    "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender",
    "formula.annual-new-graduate-employed-five-year-retention-rate-of-college-graduate-by-gender",
  ],
  [
    "annual_mid_career_employed_three_year_retention_rate_by_gender",
    "formula.annual-mid-career-employed-three-year-retention-rate-by-gender",
  ],
  [
    "annual_mid_career_employed_five_year_retention_rate_by_gender",
    "formula.annual-mid-career-employed-five-year-retention-rate-by-gender",
  ],
  [
    "annual_employed_composition_of_all_employee_by_gender",
    "formula.annual-employed-composition-of-all-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_gender",
    "formula.annual-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_non_regular_employee_by_gender",
    "formula.annual-employed-composition-of-non-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_employment_type",
    "formula.annual-employed-composition-of-regular-employee-by-employment-type",
  ],
  [
    "annual_new_graduate_employed_composition_of_regular_employee_by_gender",
    "formula.annual-new-graduate-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_mid_career_employed_composition_of_regular_employee_by_gender",
    "formula.annual-mid-career-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "employment_ratio_age_distribution_of_regular_employee_by_gender",
    "formula.employment-ratio-age-distribution-of-regular-employee-by-gender",
  ],
  [
    "mid_career_employed_headcount_and_rate_of_all_employee",
    "formula.mid-career-employed-headcount-and-rate-of-all-employee",
  ],
  ["mid_career_employed_headcount_and_rate_of_manager", "formula.mid-career-employed-headcount-and-rate-of-manager"],
  ["mid_career_employed_executive_headcount_and_rate", "formula.mid-career-employed-executive-headcount-and-rate"],
  [
    "separated_headcount_and_rate_of_regular_employee_by_gender",
    "formula.separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  [
    "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender",
    "formula.personal-reason-separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  ["executive_gender_composition", "formula.executive-gender-composition"],
  ["female_headcount_and_rate_of_division_manager", "formula.female-headcount-and-rate-of-division-manager"],
  ["female_headcount_and_rate_of_section_manager", "formula.female-headcount-and-rate-of-section-manager"],
  ["female_headcount_and_rate_of_team_leader", "formula.female-headcount-and-rate-of-team-leader"],
  [
    "childcare_leave_headcount_and_rate_of_all_employee_by_gender",
    "formula.childcare-leave-headcount-and-rate-of-all-employee-by-gender",
  ],
  [
    "childcare_leave_headcount_and_rate_of_regular_employee_by_gender",
    "formula.childcare-leave-headcount-and-rate-of-regular-employee-by-gender",
  ],
  [
    "childcare_leave_headcount_and_rate_of_non_regular_employee_by_gender",
    "formula.childcare-leave-headcount-and-rate-of-non-regular-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_all_employee_by_gender",
    "formula.average-childcare-leave-taken-days-of-all-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_regular_employee_by_gender",
    "formula.average-childcare-leave-taken-days-of-regular-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_non_regular_employee_by_gender",
    "formula.average-childcare-leave-taken-days-of-non-regular-employee-by-gender",
  ],
  ["male_childcare_leave_taken_day_distribution", "formula.male-childcare-leave-taken-day-distribution"],
  [
    "foreign_employee_headcount_and_rate_of_all_employee",
    "formula.foreign-employee-headcount-and-rate-of-all-employee",
  ],
  ["foreign_employee_headcount_and_rate_of_manager", "formula.foreign-employee-headcount-and-rate-of-manager"],
  ["foreign_new_graduate_employed_headcount_and_rate", "formula.foreign-new-graduate-employed-headcount-and-rate"],
  ["foreign_executive_headcount_and_rate", "formula.foreign-executive-headcount-and-rate"],
  ["disabled_employment_headcount_and_rate", "formula.disabled-employment-headcount-and-rate"],
  ["disabled_employment_shortage_headcount", "formula.disabled-employment-shortage-headcount"],
  [
    "average_annual_salary_of_regular_employee_by_gender",
    "formula.average-annual-salary-of-regular-employee-by-gender",
  ],
  ["average_annual_bonus_of_regular_employee_by_gender", "formula.average-annual-bonus-of-regular-employee-by-gender"],
  [
    "average_annual_bonus_of_regular_employee_by_grade_type",
    "formula.average-annual-bonus-of-regular-employee-by-grade-type",
  ],
  [
    "salary_increase_rate_of_regular_employee_by_management_type",
    "formula.salary-increase-rate-of-regular-employee-by-management-type",
  ],
  [
    "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender",
    "formula.regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender",
  ],
  [
    "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender",
    "formula.regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee",
    "formula.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager",
    "formula.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager",
    "formula.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee",
    "formula.regular-employee-average-annual-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager",
    "formula.regular-employee-average-annual-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager",
    "formula.regular-employee-average-annual-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_all_employee",
    "formula.average-annual-paid-leave-consumed-days-and-rate-of-all-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee",
    "formula.average-annual-paid-leave-consumed-days-and-rate-of-regular-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee",
    "formula.average-annual-paid-leave-consumed-days-and-rate-of-non-regular-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee",
    "formula.childcare-purpose-short-time-work-headcount-and-rate-of-all-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee",
    "formula.childcare-purpose-short-time-work-headcount-and-rate-of-male-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee",
    "formula.childcare-purpose-short-time-work-headcount-and-rate-of-female-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee",
    "formula.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-all-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee",
    "formula.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-male-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee",
    "formula.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-female-employee",
  ],
]);

export const ChartGroupTextDef = new Map<ChartGroup, string>([
  ["gender_pay_gap", "group.gender-pay-gap"],
  ["female_manager_ratio", "group.female-manager-ratio"],
  ["male_childcare_leave_rate", "group.male-childcare-leave-rate"],
  ["gender_composition", "group.gender-composition"],
  ["employee_composition", "group.employee-composition"],
  ["span_of_control", "group.span-of-control"],
  ["average_age", "group.average-age"],
  ["age_distribution", "group.age-distribution"],
  ["service_year", "group.service-year"],
  ["employed_retention_rate", "group.employed-retention-rate"],
  ["annual_employed_composition", "group.annual-employed-composition"],
  ["mid_career_employed", "group.mid-career-employed"],
  ["separated", "group.separated"],
  ["employment_age_distribution", "group.employment-age-distribution"],
  ["executive_composition", "group.executive-composition"],
  ["female_headcount_and_rate_by_grade", "group.female-headcount-and-rate-by-grade"],
  ["childcare_leave", "group.childcare-leave"],
  ["foreign_employee", "group.foreign-employee"],
  ["disabled_employment", "group.disabled-employment"],
  ["actual_working_hours", "group.actual-working-hours"],
  ["over_scheduled_working_hours", "group.over-scheduled-working-hours"],
  ["paid_leave_consumed_days", "group.paid-leave-consumed-days"],
  ["employees_using_childcare_purpose_attendance_system", "group.employees-using-childcare-purpose-attendance-system"],
  ["familycare_leave", "group.familycare-leave"],
  ["average_annual_salary", "group.average-annual-salary"],
  ["average_annual_bonus", "group.average-annual-bonus"],
  ["salary_increase_rate", "group.salary-increase-rate"],
  ["discipline", "group.discipline"],
  ["gender_pay_gap_of_all_employees", "segment.group.gender-pay-gap-of-all-employees"],
  ["gender_pay_gap_of_regular_employees", "segment.group.gender-pay-gap-of-regular-employees"],
  ["gender_pay_gap_of_non_regular_employees", "segment.group.gender-pay-gap-of-non-regular-employees"],
  ["male_childcare_leave_rate_of_all_employee", "segment.group.male-childcare-leave-rate-of-all-employee"],
  ["male_childcare_leave_rate_of_regular_employee", "segment.group.male-childcare-leave-rate-of-regular-employee"],
  [
    "male_childcare_leave_rate_of_non_regular_employee",
    "segment.group.male-childcare-leave-rate-of-non-regular-employee",
  ],
  [
    "gender_composition_of_all_employee_at_male_headcount",
    "segment.group.gender-composition-of-all-employee-at-male-headcount",
  ],
  [
    "gender_composition_of_all_employee_at_female_headcount",
    "segment.group.gender-composition-of-all-employee-at-female-headcount",
  ],
  [
    "gender_composition_of_all_employee_at_male_ratio",
    "segment.group.gender-composition-of-all-employee-at-male-ratio",
  ],
  [
    "gender_composition_of_all_employee_at_female_ratio",
    "segment.group.gender-composition-of-all-employee-at-female-ratio",
  ],
  [
    "gender_composition_of_regular_employee_at_male_headcount",
    "segment.group.gender-composition-of-regular-employee-at-male-headcount",
  ],
  [
    "gender_composition_of_regular_employee_at_female_headcount",
    "segment.group.gender-composition-of-regular-employee-at-female-headcount",
  ],
  [
    "gender_composition_of_regular_employee_at_male_ratio",
    "segment.group.gender-composition-of-regular-employee-at-male-ratio",
  ],
  [
    "gender_composition_of_regular_employee_at_female_ratio",
    "segment.group.gender-composition-of-regular-employee-at-female-ratio",
  ],
  [
    "gender_composition_of_non_regular_employee_at_male_headcount",
    "segment.group.gender-composition-of-non-regular-employee-at-male-headcount",
  ],
  [
    "gender_composition_of_non_regular_employee_at_female_headcount",
    "segment.group.gender-composition-of-non-regular-employee-at-female-headcount",
  ],
  [
    "gender_composition_of_non_regular_employee_at_male_ratio",
    "segment.group.gender-composition-of-non-regular-employee-at-male-ratio",
  ],
  [
    "gender_composition_of_non_regular_employee_at_female_ratio",
    "segment.group.gender-composition-of-non-regular-employee-at-female-ratio",
  ],
]);

export const ChartTextDef = new Map<Chart, string>([
  ["gender_pay_gap", "chart.gender-pay-gap"],
  ["female_manager_ratio", "chart.female-manager-ratio"],
  ["male_childcare_leave_rate", "chart.male-childcare-leave-rate"],
  ["male_childcare_leave_rate_by_employee_type", "chart.male-childcare-leave-rate-by-employee-type"],
  [
    "male_childcare_leave_or_childcare_purpose_leave_rate",
    "chart.male-childcare-leave-or-childcare-purpose-leave-rate",
  ],
  ["gender_composition_of_all_employee", "chart.gender-composition-of-all-employee"],
  ["gender_composition_of_regular_employee", "chart.gender-composition-of-regular-employee"],
  ["gender_composition_of_non_regular_employee", "chart.gender-composition-of-non-regular-employee"],
  ["full_time_employee_headcount_by_gender", "chart.full-time-employee-headcount-by-gender"],
  ["full_time_employee_headcount_by_employee_type", "chart.full-time-employee-headcount-by-employee-type"],
  ["manager_rate", "chart.manager-rate"],
  ["span_of_control", "chart.span-of-control"],
  ["province_span_of_control_of_executive", "chart.province-span-of-control-of-executive"],
  ["province_span_of_control_of_executive_officer", "chart.province-span-of-control-of-executive-officer"],
  ["province_span_of_control_of_general_manager", "chart.province-span-of-control-of-general-manager"],
  ["province_span_of_control_of_division_manager", "chart.province-span-of-control-of-division-manager"],
  ["province_span_of_control_of_section_manager", "chart.province-span-of-control-of-section-manager"],
  ["direct_span_of_control_of_executive", "chart.direct-span-of-control-of-executive"],
  ["direct_span_of_control_of_executive_officer", "chart.direct-span-of-control-of-executive-officer"],
  ["direct_span_of_control_of_general_manager", "chart.direct-span-of-control-of-general-manager"],
  ["direct_span_of_control_of_division_manager", "chart.direct-span-of-control-of-division-manager"],
  ["direct_span_of_control_of_section_manager", "chart.direct-span-of-control-of-section-manager"],
  ["average_age_of_all_employee_by_gender", "chart.average-age-of-all-employee-by-gender"],
  ["average_age_of_regular_employee_by_gender", "chart.average-age-of-regular-employee-by-gender"],
  ["average_age_of_executive_officer_by_gender", "chart.average-age-of-executive-officer-by-gender"],
  ["average_age_of_general_manager_by_gender", "chart.average-age-of-general-manager-by-gender"],
  ["average_age_of_division_manager_by_gender", "chart.average-age-of-division-manager-by-gender"],
  ["average_age_of_section_manager_by_gender", "chart.average-age-of-section-manager-by-gender"],
  ["average_age_of_team_leader_by_gender", "chart.average-age-of-team-leader-by-gender"],
  ["average_age_of_staff_by_gender", "chart.average-age-of-staff-by-gender"],
  ["average_age_of_manager_by_gender", "chart.average-age-of-manager-by-gender"],
  [
    "headcount_age_distribution_of_regular_employee_by_gender",
    "chart.headcount-age-distribution-of-regular-employee-by-gender",
  ],
  [
    "ratio_age_distribution_of_regular_employee_by_gender",
    "chart.ratio-age-distribution-of-regular-employee-by-gender",
  ],
  ["headcount_age_distribution_of_manager_by_gender", "chart.headcount-age-distribution-of-manager-by-gender"],
  ["ratio_age_distribution_of_manager_by_gender", "chart.ratio-age-distribution-of-manager-by-gender"],
  ["average_service_year_of_all_employee_by_gender", "chart.average-service-year-of-all-employee-by-gender"],
  ["average_service_year_of_regular_employee_by_gender", "chart.average-service-year-of-regular-employee-by-gender"],
  [
    "average_service_year_of_non_regular_employee_by_gender",
    "chart.average-service-year-of-non-regular-employee-by-gender",
  ],
  ["full_time_employee_average_service_year_by_gender", "chart.full-time-employee-average-service-year-by-gender"],
  ["gender_average_service_year_gap_of_all_employee", "chart.gender-average-service-year-gap-of-all-employee"],
  ["gender_average_service_year_gap_of_regular_employee", "chart.gender-average-service-year-gap-of-regular-employee"],
  [
    "gender_average_service_year_gap_of_non_regular_employee",
    "chart.gender-average-service-year-gap-of-non-regular-employee",
  ],
  ["full_time_employee_gender_average_service_year_gap", "chart.full-time-employee-gender-average-service-year-gap"],
  [
    "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender",
    "chart.annual-new-graduate-employed-three-year-retention-rate-of-college-graduate-by-gender",
  ],
  [
    "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender",
    "chart.annual-new-graduate-employed-five-year-retention-rate-of-college-graduate-by-gender",
  ],
  [
    "annual_mid_career_employed_three_year_retention_rate_by_gender",
    "chart.annual-mid-career-employed-three-year-retention-rate-by-gender",
  ],
  [
    "annual_mid_career_employed_five_year_retention_rate_by_gender",
    "chart.annual-mid-career-employed-five-year-retention-rate-by-gender",
  ],
  [
    "annual_employed_composition_of_all_employee_by_gender",
    "chart.annual-employed-composition-of-all-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_gender",
    "chart.annual-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_non_regular_employee_by_gender",
    "chart.annual-employed-composition-of-non-regular-employee-by-gender",
  ],
  [
    "annual_employed_composition_of_regular_employee_by_employment_type",
    "chart.annual-employed-composition-of-regular-employee-by-employment-type",
  ],
  [
    "annual_new_graduate_employed_composition_of_regular_employee_by_gender",
    "chart.annual-new-graduate-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "annual_mid_career_employed_composition_of_regular_employee_by_gender",
    "chart.annual-mid-career-employed-composition-of-regular-employee-by-gender",
  ],
  [
    "employment_headcount_age_distribution_of_regular_employee_by_gender",
    "chart.employment-headcount-age-distribution-of-regular-employee-by-gender",
  ],
  [
    "employment_ratio_age_distribution_of_regular_employee_by_gender",
    "chart.employment-ratio-age-distribution-of-regular-employee-by-gender",
  ],
  [
    "mid_career_employed_headcount_and_rate_of_all_employee",
    "chart.mid-career-employed-headcount-and-rate-of-all-employee",
  ],
  ["mid_career_employed_headcount_and_rate_of_manager", "chart.mid-career-employed-headcount-and-rate-of-manager"],
  ["mid_career_employed_executive_headcount_and_rate", "chart.mid-career-employed-executive-headcount-and-rate"],
  [
    "separated_headcount_and_rate_of_regular_employee_by_gender",
    "chart.separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  [
    "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender",
    "chart.personal-reason-separated-headcount-and-rate-of-regular-employee-by-gender",
  ],
  ["executive_gender_composition", "chart.executive-gender-composition"],
  ["female_headcount_and_rate_of_division_manager", "chart.female-headcount-and-rate-of-division-manager"],
  ["female_headcount_and_rate_of_section_manager", "chart.female-headcount-and-rate-of-section-manager"],
  ["female_headcount_and_rate_of_team_leader", "chart.female-headcount-and-rate-of-team-leader"],
  [
    "childcare_leave_headcount_and_rate_of_all_employee_by_gender",
    "chart.childcare-leave-headcount-and-rate-of-all-employee-by-gender",
  ],
  [
    "childcare_leave_headcount_and_rate_of_regular_employee_by_gender",
    "chart.childcare-leave-headcount-and-rate-of-regular-employee-by-gender",
  ],
  [
    "childcare_leave_headcount_and_rate_of_non_regular_employee_by_gender",
    "chart.childcare-leave-headcount-and-rate-of-non-regular-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_all_employee_by_gender",
    "chart.average-childcare-leave-taken-days-of-all-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_regular_employee_by_gender",
    "chart.average-childcare-leave-taken-days-of-regular-employee-by-gender",
  ],
  [
    "average_childcare_leave_taken_days_of_non_regular_employee_by_gender",
    "chart.average-childcare-leave-taken-days-of-non-regular-employee-by-gender",
  ],
  ["male_childcare_leave_taken_day_distribution", "chart.male-childcare-leave-taken-day-distribution"],
  ["foreign_employee_headcount_by_nationality", "chart.foreign-employee-headcount-by-nationality"],
  ["foreign_employee_headcount_by_gender", "chart.foreign-employee-headcount-by-gender"],
  ["foreign_employee_headcount_and_rate_of_all_employee", "chart.foreign-employee-headcount-and-rate-of-all-employee"],
  ["foreign_employee_headcount_and_rate_of_manager", "chart.foreign-employee-headcount-and-rate-of-manager"],
  ["foreign_new_graduate_employed_headcount_and_rate", "chart.foreign-new-graduate-employed-headcount-and-rate"],
  ["foreign_executive_headcount_and_rate", "chart.foreign-executive-headcount-and-rate"],
  ["disabled_employment_headcount_and_rate", "chart.disabled-employment-headcount-and-rate"],
  ["disabled_employment_shortage_headcount", "chart.disabled-employment-shortage-headcount"],
  ["average_annual_salary_of_regular_employee_by_gender", "chart.average-annual-salary-of-regular-employee-by-gender"],
  ["average_annual_bonus_of_regular_employee_by_gender", "chart.average-annual-bonus-of-regular-employee-by-gender"],
  [
    "average_annual_bonus_of_regular_employee_by_grade_type",
    "chart.average-annual-bonus-of-regular-employee-by-grade-type",
  ],
  [
    "salary_increase_rate_of_regular_employee_by_management_type",
    "chart.salary-increase-rate-of-regular-employee-by-management-type",
  ],
  [
    "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender",
    "chart.regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender",
  ],
  [
    "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender",
    "chart.regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee",
    "chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager",
    "chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager",
    "chart.regular-employee-average-monthly-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee",
    "chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-all-employee",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager",
    "chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-manager",
  ],
  [
    "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager",
    "chart.regular-employee-average-annual-hours-over-scheduled-working-hours-of-non-manager",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_all_employee",
    "chart.average-annual-paid-leave-consumed-days-and-rate-of-all-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee",
    "chart.average-annual-paid-leave-consumed-days-and-rate-of-regular-employee",
  ],
  [
    "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee",
    "chart.average-annual-paid-leave-consumed-days-and-rate-of-non-regular-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee",
    "chart.childcare-purpose-short-time-work-headcount-and-rate-of-all-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee",
    "chart.childcare-purpose-short-time-work-headcount-and-rate-of-male-employee",
  ],
  [
    "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee",
    "chart.childcare-purpose-short-time-work-headcount-and-rate-of-female-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee",
    "chart.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-all-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee",
    "chart.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-male-employee",
  ],
  [
    "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee",
    "chart.childcare-purpose-shifted-start-time-work-headcount-and-rate-of-female-employee",
  ],
  ["familycare_leave_headcount_by_gender", "chart.familycare-leave-headcount-by-gender"],
  ["discipline_count_by_discipline_type", "chart.discipline-count-by-discipline-type"],
  ["gender_pay_gap_of_all_employees_by_company", "segment.chart.gender-pay-gap-of-all-employees-by-company"],
  ["gender_pay_gap_of_regular_employees_by_company", "segment.chart.gender-pay-gap-of-regular-employees-by-company"],
  [
    "gender_pay_gap_of_non_regular_employees_by_company",
    "segment.chart.gender-pay-gap-of-non-regular-employees-by-company",
  ],
  [
    "male_childcare_leave_rate_of_all_employee_by_company",
    "segment.chart.male-childcare-leave-rate-of-all-employee-by-company",
  ],
  [
    "male_childcare_leave_rate_of_regular_employee_by_company",
    "segment.chart.male-childcare-leave-rate-of-regular-employee-by-company",
  ],
  [
    "male_childcare_leave_rate_of_non_regular_employee_by_company",
    "segment.chart.male-childcare-leave-rate-of-non-regular-employee-by-company",
  ],
  [
    "gender_composition_of_all_employee_at_male_headcount_by_company",
    "segment.chart.gender-composition-of-all-employee-at-male-headcount-by-company",
  ],
  [
    "gender_composition_of_all_employee_at_female_headcount_by_company",
    "segment.chart.gender-composition-of-all-employee-at-female-headcount-by-company",
  ],
  [
    "gender_composition_of_all_employee_at_male_ratio_by_company",
    "segment.chart.gender-composition-of-all-employee-at-male-ratio-by-company",
  ],
  [
    "gender_composition_of_all_employee_at_female_ratio_by_company",
    "segment.chart.gender-composition-of-all-employee-at-female-ratio-by-company",
  ],
  [
    "gender_composition_of_regular_employee_at_male_headcount_by_company",
    "segment.chart.gender-composition-of-regular-employee-at-male-headcount-by-company",
  ],
  [
    "gender_composition_of_regular_employee_at_female_headcount_by_company",
    "segment.chart.gender-composition-of-regular-employee-at-female-headcount-by-company",
  ],
  [
    "gender_composition_of_regular_employee_at_male_ratio_by_company",
    "segment.chart.gender-composition-of-regular-employee-at-male-ratio-by-company",
  ],
  [
    "gender_composition_of_regular_employee_at_female_ratio_by_company",
    "segment.chart.gender-composition-of-regular-employee-at-female-ratio-by-company",
  ],
  [
    "gender_composition_of_non_regular_employee_at_male_headcount_by_company",
    "segment.chart.gender-composition-of-non-regular-employee-at-male-headcount-by-company",
  ],
  [
    "gender_composition_of_non_regular_employee_at_female_headcount_by_company",
    "segment.chart.gender-composition-of-non-regular-employee-at-female-headcount-by-company",
  ],
  [
    "gender_composition_of_non_regular_employee_at_male_ratio_by_company",
    "segment.chart.gender-composition-of-non-regular-employee-at-male-ratio-by-company",
  ],
  [
    "gender_composition_of_non_regular_employee_at_female_ratio_by_company",
    "segment.chart.gender-composition-of-non-regular-employee-at-female-ratio-by-company",
  ],
]);

export const IndustryDivisionTextDef = new Map<IndustryDivision, string>([
  ["A", "industry-division.agriculture-and-forestry"],
  ["B", "industry-division.fisheries"],
  ["C", "industry-division.mining-and-quarrying-of-stone-and-gravel"],
  ["D", "industry-division.construction"],
  ["E", "industry-division.manufacturing"],
  ["F", "industry-division.electricity&gas&heat-supply-and-water"],
  ["G", "industry-division.information-and-communications"],
  ["H", "industry-division.transport-and-postal-services"],
  ["I", "industry-division.wholesale-and-retail-trade"],
  ["J", "industry-division.finance-and-insurance"],
  ["K", "industry-division.real-estate-and-goods-rental-and-leasing"],
  ["L", "industry-division.scientific-research&professional-and-technical-services"],
  ["M", "industry-division.accommodations&eating-and-drinking-services"],
  ["N", "industry-division.living-related-and-personal-services-and-amusement-services"],
  ["O", "industry-division.education&learning-support"],
  ["P", "industry-division.medical&health-care-and-welfare"],
  ["Q", "industry-division.compound-services"],
  ["R", "industry-division.services&n-e-c"],
  ["S", "industry-division.government&except-elsewhere-classified"],
  ["T", "industry-division.industries_unable_to_classify"],
]);

export const IndustryMajorGroupTextDef = new Map<IndustryMajorGroup, string>([
  ["01", "industry-major-group.01"],
  ["02", "industry-major-group.02"],
  ["03", "industry-major-group.03"],
  ["04", "industry-major-group.04"],
  ["05", "industry-major-group.05"],
  ["06", "industry-major-group.06"],
  ["07", "industry-major-group.07"],
  ["08", "industry-major-group.08"],
  ["09", "industry-major-group.09"],
  ["10", "industry-major-group.10"],
  ["11", "industry-major-group.11"],
  ["12", "industry-major-group.12"],
  ["13", "industry-major-group.13"],
  ["14", "industry-major-group.14"],
  ["15", "industry-major-group.15"],
  ["16", "industry-major-group.16"],
  ["17", "industry-major-group.17"],
  ["18", "industry-major-group.18"],
  ["19", "industry-major-group.19"],
  ["20", "industry-major-group.20"],
  ["21", "industry-major-group.21"],
  ["22", "industry-major-group.22"],
  ["23", "industry-major-group.23"],
  ["24", "industry-major-group.24"],
  ["25", "industry-major-group.25"],
  ["26", "industry-major-group.26"],
  ["27", "industry-major-group.27"],
  ["28", "industry-major-group.28"],
  ["29", "industry-major-group.29"],
  ["30", "industry-major-group.30"],
  ["31", "industry-major-group.31"],
  ["32", "industry-major-group.32"],
  ["33", "industry-major-group.33"],
  ["34", "industry-major-group.34"],
  ["35", "industry-major-group.35"],
  ["36", "industry-major-group.36"],
  ["37", "industry-major-group.37"],
  ["38", "industry-major-group.38"],
  ["39", "industry-major-group.39"],
  ["40", "industry-major-group.40"],
  ["41", "industry-major-group.41"],
  ["42", "industry-major-group.42"],
  ["43", "industry-major-group.43"],
  ["44", "industry-major-group.44"],
  ["45", "industry-major-group.45"],
  ["46", "industry-major-group.46"],
  ["47", "industry-major-group.47"],
  ["48", "industry-major-group.48"],
  ["49", "industry-major-group.49"],
  ["50", "industry-major-group.50"],
  ["51", "industry-major-group.51"],
  ["52", "industry-major-group.52"],
  ["53", "industry-major-group.53"],
  ["54", "industry-major-group.54"],
  ["55", "industry-major-group.55"],
  ["56", "industry-major-group.56"],
  ["57", "industry-major-group.57"],
  ["58", "industry-major-group.58"],
  ["59", "industry-major-group.59"],
  ["60", "industry-major-group.60"],
  ["61", "industry-major-group.61"],
  ["62", "industry-major-group.62"],
  ["63", "industry-major-group.63"],
  ["64", "industry-major-group.64"],
  ["65", "industry-major-group.65"],
  ["66", "industry-major-group.66"],
  ["67", "industry-major-group.67"],
  ["68", "industry-major-group.68"],
  ["69", "industry-major-group.69"],
  ["70", "industry-major-group.70"],
  ["71", "industry-major-group.71"],
  ["72", "industry-major-group.72"],
  ["73", "industry-major-group.73"],
  ["74", "industry-major-group.74"],
  ["75", "industry-major-group.75"],
  ["76", "industry-major-group.76"],
  ["77", "industry-major-group.77"],
  ["78", "industry-major-group.78"],
  ["79", "industry-major-group.79"],
  ["80", "industry-major-group.80"],
  ["81", "industry-major-group.81"],
  ["82", "industry-major-group.82"],
  ["83", "industry-major-group.83"],
  ["84", "industry-major-group.84"],
  ["85", "industry-major-group.85"],
  ["86", "industry-major-group.86"],
  ["87", "industry-major-group.87"],
  ["88", "industry-major-group.88"],
  ["89", "industry-major-group.89"],
  ["90", "industry-major-group.90"],
  ["91", "industry-major-group.91"],
  ["92", "industry-major-group.92"],
  ["93", "industry-major-group.93"],
  ["94", "industry-major-group.94"],
  ["95", "industry-major-group.95"],
  ["96", "industry-major-group.96"],
  ["97", "industry-major-group.97"],
  ["98", "industry-major-group.98"],
  ["99", "industry-major-group.99"],
]);
export const IndustryGroupTextDef = new Map<IndustryGroup, string>([
  ["020", "industry-group.020"],
  ["021", "industry-group.021"],
  ["022", "industry-group.022"],
  ["023", "industry-group.023"],
  ["024", "industry-group.024"],
  ["029", "industry-group.029"],
  ["051", "industry-group.051"],
  ["052", "industry-group.052"],
  ["054", "industry-group.054"],
  ["055", "industry-group.055"],
  ["059", "industry-group.059"],
  ["060", "industry-group.060"],
  ["061", "industry-group.061"],
  ["062", "industry-group.062"],
  ["063", "industry-group.063"],
  ["064", "industry-group.064"],
  ["065", "industry-group.065"],
  ["066", "industry-group.066"],
  ["070", "industry-group.070"],
  ["071", "industry-group.071"],
  ["072", "industry-group.072"],
  ["073", "industry-group.073"],
  ["074", "industry-group.074"],
  ["075", "industry-group.075"],
  ["076", "industry-group.076"],
  ["077", "industry-group.077"],
  ["078", "industry-group.078"],
  ["079", "industry-group.079"],
  ["080", "industry-group.080"],
  ["081", "industry-group.081"],
  ["082", "industry-group.082"],
  ["083", "industry-group.083"],
  ["084", "industry-group.084"],
  ["089", "industry-group.089"],
  ["220", "industry-group.220"],
  ["221", "industry-group.221"],
  ["222", "industry-group.222"],
  ["223", "industry-group.223"],
  ["224", "industry-group.224"],
  ["225", "industry-group.225"],
  ["229", "industry-group.229"],
  ["230", "industry-group.230"],
  ["231", "industry-group.231"],
  ["232", "industry-group.232"],
  ["233", "industry-group.233"],
  ["234", "industry-group.234"],
  ["235", "industry-group.235"],
  ["239", "industry-group.239"],
  ["313", "industry-group.313"],
  ["420", "industry-group.420"],
  ["421", "industry-group.421"],
  ["430", "industry-group.430"],
  ["431", "industry-group.431"],
  ["432", "industry-group.432"],
  ["433", "industry-group.433"],
  ["439", "industry-group.439"],
  ["440", "industry-group.440"],
  ["441", "industry-group.441"],
  ["442", "industry-group.442"],
  ["443", "industry-group.443"],
  ["444", "industry-group.444"],
  ["449", "industry-group.449"],
  ["450", "industry-group.450"],
  ["451", "industry-group.451"],
  ["452", "industry-group.452"],
  ["453", "industry-group.453"],
  ["454", "industry-group.454"],
  ["460", "industry-group.460"],
  ["461", "industry-group.461"],
  ["462", "industry-group.462"],
  ["470", "industry-group.470"],
  ["471", "industry-group.471"],
  ["472", "industry-group.472"],
  ["481", "industry-group.481"],
  ["482", "industry-group.482"],
  ["490", "industry-group.490"],
  ["491", "industry-group.491"],
  ["811", "industry-group.811"],
  ["812", "industry-group.812"],
  ["815", "industry-group.815"],
  ["816", "industry-group.816"],
  ["819", "industry-group.819"],
  ["830", "industry-group.830"],
  ["831", "industry-group.831"],
  ["832", "industry-group.832"],
  ["833", "industry-group.833"],
  ["834", "industry-group.834"],
  ["835", "industry-group.835"],
  ["836", "industry-group.836"],
  ["853", "industry-group.853"],
  ["854", "industry-group.854"],
  ["923", "industry-group.923"],
]);
export const IndustryDetailTextDef = new Map<IndustryDetail, string>([
  ["0200", "industry-detail.0200"],
  ["0209", "industry-detail.0209"],
  ["0211", "industry-detail.0211"],
  ["0221", "industry-detail.0221"],
  ["0231", "industry-detail.0231"],
  ["0239", "industry-detail.0239"],
  ["0241", "industry-detail.0241"],
  ["0242", "industry-detail.0242"],
  ["0243", "industry-detail.0243"],
  ["0249", "industry-detail.0249"],
  ["0299", "industry-detail.0299"],
  ["0511", "industry-detail.0511"],
  ["0512", "industry-detail.0512"],
  ["0513", "industry-detail.0513"],
  ["0519", "industry-detail.0519"],
  ["0521", "industry-detail.0521"],
  ["0522", "industry-detail.0522"],
  ["0541", "industry-detail.0541"],
  ["0542", "industry-detail.0542"],
  ["0543", "industry-detail.0543"],
  ["0544", "industry-detail.0544"],
  ["0545", "industry-detail.0545"],
  ["0546", "industry-detail.0546"],
  ["0547", "industry-detail.0547"],
  ["0548", "industry-detail.0548"],
  ["0549", "industry-detail.0549"],
  ["0551", "industry-detail.0551"],
  ["0552", "industry-detail.0552"],
  ["0553", "industry-detail.0553"],
  ["0554", "industry-detail.0554"],
  ["0555", "industry-detail.0555"],
  ["0556", "industry-detail.0556"],
  ["0557", "industry-detail.0557"],
  ["0559", "industry-detail.0559"],
  ["0591", "industry-detail.0591"],
  ["0592", "industry-detail.0592"],
  ["0593", "industry-detail.0593"],
  ["0594", "industry-detail.0594"],
  ["0599", "industry-detail.0599"],
  ["0600", "industry-detail.0600"],
  ["0609", "industry-detail.0609"],
  ["0611", "industry-detail.0611"],
  ["0621", "industry-detail.0621"],
  ["0622", "industry-detail.0622"],
  ["0623", "industry-detail.0623"],
  ["0631", "industry-detail.0631"],
  ["0641", "industry-detail.0641"],
  ["0651", "industry-detail.0651"],
  ["0661", "industry-detail.0661"],
  ["0700", "industry-detail.0700"],
  ["0709", "industry-detail.0709"],
  ["0711", "industry-detail.0711"],
  ["0712", "industry-detail.0712"],
  ["0721", "industry-detail.0721"],
  ["0722", "industry-detail.0722"],
  ["0723", "industry-detail.0723"],
  ["0731", "industry-detail.0731"],
  ["0732", "industry-detail.0732"],
  ["0741", "industry-detail.0741"],
  ["0742", "industry-detail.0742"],
  ["0743", "industry-detail.0743"],
  ["0744", "industry-detail.0744"],
  ["0751", "industry-detail.0751"],
  ["0761", "industry-detail.0761"],
  ["0762", "industry-detail.0762"],
  ["0763", "industry-detail.0763"],
  ["0771", "industry-detail.0771"],
  ["0772", "industry-detail.0772"],
  ["0781", "industry-detail.0781"],
  ["0782", "industry-detail.0782"],
  ["0791", "industry-detail.0791"],
  ["0792", "industry-detail.0792"],
  ["0793", "industry-detail.0793"],
  ["0794", "industry-detail.0794"],
  ["0795", "industry-detail.0795"],
  ["0796", "industry-detail.0796"],
  ["0799", "industry-detail.0799"],
  ["0800", "industry-detail.0800"],
  ["0809", "industry-detail.0809"],
  ["0811", "industry-detail.0811"],
  ["0812", "industry-detail.0812"],
  ["0821", "industry-detail.0821"],
  ["0822", "industry-detail.0822"],
  ["0823", "industry-detail.0823"],
  ["0831", "industry-detail.0831"],
  ["0832", "industry-detail.0832"],
  ["0833", "industry-detail.0833"],
  ["0839", "industry-detail.0839"],
  ["0841", "industry-detail.0841"],
  ["0842", "industry-detail.0842"],
  ["0891", "industry-detail.0891"],
  ["0892", "industry-detail.0892"],
  ["0893", "industry-detail.0893"],
  ["0894", "industry-detail.0894"],
  ["2200", "industry-detail.2200"],
  ["2209", "industry-detail.2209"],
  ["2211", "industry-detail.2211"],
  ["2212", "industry-detail.2212"],
  ["2213", "industry-detail.2213"],
  ["2221", "industry-detail.2221"],
  ["2231", "industry-detail.2231"],
  ["2232", "industry-detail.2232"],
  ["2233", "industry-detail.2233"],
  ["2234", "industry-detail.2234"],
  ["2235", "industry-detail.2235"],
  ["2236", "industry-detail.2236"],
  ["2237", "industry-detail.2237"],
  ["2238", "industry-detail.2238"],
  ["2239", "industry-detail.2239"],
  ["2241", "industry-detail.2241"],
  ["2249", "industry-detail.2249"],
  ["2251", "industry-detail.2251"],
  ["2252", "industry-detail.2252"],
  ["2253", "industry-detail.2253"],
  ["2254", "industry-detail.2254"],
  ["2255", "industry-detail.2255"],
  ["2291", "industry-detail.2291"],
  ["2292", "industry-detail.2292"],
  ["2293", "industry-detail.2293"],
  ["2299", "industry-detail.2299"],
  ["2300", "industry-detail.2300"],
  ["2309", "industry-detail.2309"],
  ["2311", "industry-detail.2311"],
  ["2312", "industry-detail.2312"],
  ["2319", "industry-detail.2319"],
  ["2321", "industry-detail.2321"],
  ["2322", "industry-detail.2322"],
  ["2329", "industry-detail.2329"],
  ["2331", "industry-detail.2331"],
  ["2332", "industry-detail.2332"],
  ["2339", "industry-detail.2339"],
  ["2341", "industry-detail.2341"],
  ["2342", "industry-detail.2342"],
  ["2351", "industry-detail.2351"],
  ["2352", "industry-detail.2352"],
  ["2353", "industry-detail.2353"],
  ["2354", "industry-detail.2354"],
  ["2355", "industry-detail.2355"],
  ["2391", "industry-detail.2391"],
  ["2399", "industry-detail.2399"],
  ["3131", "industry-detail.3131"],
  ["3132", "industry-detail.3132"],
  ["3133", "industry-detail.3133"],
  ["3134", "industry-detail.3134"],
  ["4200", "industry-detail.4200"],
  ["4209", "industry-detail.4209"],
  ["4211", "industry-detail.4211"],
  ["4212", "industry-detail.4212"],
  ["4213", "industry-detail.4213"],
  ["4214", "industry-detail.4214"],
  ["4215", "industry-detail.4215"],
  ["4216", "industry-detail.4216"],
  ["4217", "industry-detail.4217"],
  ["4219", "industry-detail.4219"],
  ["4300", "industry-detail.4300"],
  ["4309", "industry-detail.4309"],
  ["4311", "industry-detail.4311"],
  ["4321", "industry-detail.4321"],
  ["4331", "industry-detail.4331"],
  ["4391", "industry-detail.4391"],
  ["4399", "industry-detail.4399"],
  ["4400", "industry-detail.4400"],
  ["4409", "industry-detail.4409"],
  ["4411", "industry-detail.4411"],
  ["4412", "industry-detail.4412"],
  ["4421", "industry-detail.4421"],
  ["4431", "industry-detail.4431"],
  ["4441", "industry-detail.4441"],
  ["4499", "industry-detail.4499"],
  ["4500", "industry-detail.4500"],
  ["4509", "industry-detail.4509"],
  ["4511", "industry-detail.4511"],
  ["4512", "industry-detail.4512"],
  ["4521", "industry-detail.4521"],
  ["4522", "industry-detail.4522"],
  ["4531", "industry-detail.4531"],
  ["4532", "industry-detail.4532"],
  ["4533", "industry-detail.4533"],
  ["4541", "industry-detail.4541"],
  ["4542", "industry-detail.4542"],
  ["4600", "industry-detail.4600"],
  ["4609", "industry-detail.4609"],
  ["4611", "industry-detail.4611"],
  ["4621", "industry-detail.4621"],
  ["4700", "industry-detail.4700"],
  ["4709", "industry-detail.4709"],
  ["4711", "industry-detail.4711"],
  ["4721", "industry-detail.4721"],
  ["4811", "industry-detail.4811"],
  ["4821", "industry-detail.4821"],
  ["4822", "industry-detail.4822"],
  ["4901", "industry-detail.4901"],
  ["4911", "industry-detail.4911"],
  ["8111", "industry-detail.8111"],
  ["8121", "industry-detail.8121"],
  ["8151", "industry-detail.8151"],
  ["8161", "industry-detail.8161"],
  ["8162", "industry-detail.8162"],
  ["8163", "industry-detail.8163"],
  ["8191", "industry-detail.8191"],
  ["8300", "industry-detail.8300"],
  ["8309", "industry-detail.8309"],
  ["8311", "industry-detail.8311"],
  ["8312", "industry-detail.8312"],
  ["8321", "industry-detail.8321"],
  ["8322", "industry-detail.8322"],
  ["8331", "industry-detail.8331"],
  ["8341", "industry-detail.8341"],
  ["8342", "industry-detail.8342"],
  ["8351", "industry-detail.8351"],
  ["8352", "industry-detail.8352"],
  ["8361", "industry-detail.8361"],
  ["8369", "industry-detail.8369"],
  ["8531", "industry-detail.8531"],
  ["8539", "industry-detail.8539"],
  ["8542", "industry-detail.8542"],
  ["8543", "industry-detail.8543"],
  ["9231", "industry-detail.9231"],
]);

export const CompanyTypeTextDef = new Map<CompanyType, string>([
  ["original", "company-type.original"],
  ["current", "company-type.current"],
]);

// ワークスペース

export const JobTypeTextDef = new Map<JobType, string>([
  ["import", "dataset.import"],
  ["aggregate", "data-update"],
]);

export const ImportJobStatusTextDef = new Map<JobStatus, string>([
  ["submitted", "submitted"],
  ["running", "dataset.import.status.running"],
  ["succeeded", "succeeded"],
  ["failed", "failed"],
]);

export const ExportJobStatusTextDef = new Map<JobStatus, string>([
  ["submitted", "submitted"],
  ["running", "dashboard.export.status.running"],
  ["succeeded", "succeeded"],
  ["failed", "failed"],
]);

export const AggregateJobStatusTextDef = new Map<JobStatus, string>([
  ["submitted", "submitted"],
  ["running", "dataset.update.dashboard.status.running"],
  ["succeeded", "succeeded"],
  ["failed", "failed"],
]);

export const DataSourceSystemTextDef = new Map<DataSourceSystem, string>([
  ["company", "data-source-system.company"],
  ["general", "data-source-system.general"],
]);
export const DataIntegrationTypeTextDef = new Map<DataIntegrationType, string>([
  ["api", "api"],
  ["csv", "csv"],
]);
export const ApiIntegrationTypeTextDef = new Map<ApiIntegrationType, string>([
  ["ceirs", "api-integration.type.ceirs"],
  ["cydas", "api-integration.type.cydas"],
]);
export const ApiIntegrationConfigTextDef = new Map<string, string>([
  ["url", "url"],
  ["clientId", "client-id"],
  ["clientSecret", "client-secret"],
  ["username", "username"],
  ["password", "password"],
  ["token", "token"],
  ["tenantId", "tenant-id"],
]);

export const DataTypeTextDef = new Map<DataType, string>([
  ["employee", "data-type.employee"],
  ["salary", "data-type.salary"],
  ["family", "data-type.family"],
  ["attendance", "data-type.attendance"],
  ["discipline", "data-type.discipline"],
  ["attendance_system", "data-type.attendance-system"],
]);

export const WorkspaceRoleTextDef = new Map<WorkspaceRole, string>([["owner", "member.role.owner"]]);

export const IndividualListTextDef = new Map<string, string>([
  ["female_manager_ratio_all", "individual-list.female-manager"],
  ["male_childcare_leave_rate_all", "individual-list.male-employee-taking-childcare-leave"],
  ["male_childcare_leave_rate_by_employee_type_all", "individual-list.male-employee-taking-childcare-leave"],
  [
    "male_childcare_leave_rate_by_employee_type_regular",
    "individual-list.male-regular-employee-taking-childcare-leave",
  ],
  [
    "male_childcare_leave_rate_by_employee_type_non_regular",
    "individual-list.male-non-regular-employee-taking-childcare-leave",
  ],
  [
    "male_childcare_leave_or_childcare_purpose_leave_rate_all",
    "individual-list.male-employee-taking-childcare-leave-or-childcare-purpose-leave",
  ],
]);
